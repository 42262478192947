import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useSession } from 'providers/session';

const Layout = dynamic(() => import('components/layout'));

export default function App() {
  const router = useRouter();
  const { user } = useSession();
  if (!user) {
    return router.replace('/sign-in');
  }
  return (
    <Layout>
      <main className="h-screen flex-1 relative pb-24 z-0 overflow-y-auto"></main>
    </Layout>
  );
}
